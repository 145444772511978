

<template>
  <div v-if="isUrl" class="sidebar-container" :class="{'is-active':isCollapse}">
      <logo :isCollapse="isCollapse"></logo>
      <div class="out-menu">
        <div class="inner-menu">
          <el-menu unique-opened = "true"
                   :default-active="nowTagValue"
                   mode="vertical"
                   class="el-menu-vertical-demo"
                   :collapse="isCollapse"
                   :default-openeds="opened"
          >
            <sidebar-item :menuData="menu" :isCollapse="isCollapse"></sidebar-item>
          </el-menu>
        </div>
      </div>
    <div class="home_shousuo"  @click="showCollapse">
      <svg class="icon"  aria-hidden="true">
        <use :xlink:href="isCollapse?'#icon-home_shousuo_h_icon':'#icon-home_shousuo_icon'"></use>
      </svg>
    </div>
    <div>
      <el-dialog
        title="当前为默认密码或超过90天未修改密码，请修改！"
        :visible.sync="updPwdDialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="35%"
      >
        <div>
          <el-form
            :model="formPassword"
            :rules="passRules"
            ref="formPassword"
            label-width="120px"
          >
            <el-form-item label="原始密码" prop="oldPwd">
              <el-input
                class="securityInput"
                type="password"
                v-model="formPassword.oldPwd"
                placeholder="请输入旧密码"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="havePwd ? '新密码:' : '密码:'"
              prop="newPassword"
            >
              <el-input
                class="securityInput"
                type="password"
                v-model="formPassword.newPassword"
                :placeholder="havePwd ? '请输入新密码' : '请输入密码'"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码:" prop="changepsd">
              <el-input
                class="securityInput"
                type="password"
                v-model="formPassword.changepsd"
                placeholder="请重新输入新密码"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"

            @click="savePwd('formPassword')"
          >保存</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { setUrlPath } from '@/util/util';
import { mapGetters } from 'vuex';
import SidebarItem from './sidebarItem';
import logo from './logo';
import platformMenus from '@/menus/demo/demo';
//import pub from '@/menus/pub/pubtest';
import homeMenus from '@/menus/home/home';
import cusMenus from '@/menus/cus/cus';
import entrustMenus from '@/menus/entrust/entrust';
import agreementMenus from '@/menus/agreement/agreement';
import consigneeMenus from '@/menus/consignee/consignee';
import productMenus from '@/menus/product/product';
import orgMenus from '@/menus/org/org';
import reportMenus from '@/menus/report/report';
import settingMenus from '@/menus/setting/setting';
import bankDirectMenus from '@/menus/bank_direct/bank_direct';
import paymentMenus from '@/menus/payment/payment';
// import paymentMenus from '@/menus/payment/payment';
import { deepCopy, getAuthorizedMenu } from '@/util/util';
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import {updPwdMsg} from "@/api/home/home";
import {changePassword} from "@/api/user/user";

export default {
  name: 'sidebar',
  components: { logo,SidebarItem },
  inject: ['reload'],
  data() {
    const validatePassold = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error("请输入密码"));
      } else if (value.length < 3) {
        return callback(new Error("密码至少为3位"));
      }
      return callback();
    };
    const validatePass = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('请输入密码'));
      }  else if (!/(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
        return callback(new Error('密码中必须包含字母、数字、特殊字符,且大于8位'));
      }
      return callback();
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('请再次输入密码'));
      }else if (!/(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
        return callback(new Error('密码中必须包含字母、数字、特殊字符,且大于8位'));
      }
      if (value !== this.formPassword.newPassword) {
        return callback(new Error('两次输入密码不一致!'));
      }
      return callback();
    };
    return {
      updPwdDialogVisible: false,
      passRules: {
        oldPwd: [{ validator: validatePassold, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        newPassword: [{ validator: validatePass, trigger: 'blur' }],
        changepsd: [{ validator: validatePass2, trigger: 'blur' }],
      },
      formPassword:{},
      screenWidth: document.body.clientWidth, // 屏幕宽度
      localMenu: [],
      isUrl:true,
    };
  },
  created() {
    this.updPwdMsg();
    let url=this.$route.path;
    if(url.indexOf('printPaymentSlip') ===-1 &&
      url.indexOf('printRefundNotice') ===-1 &&
      url.indexOf('printEmployeeDetail') ===-1&&
      url.indexOf('printPublicPayment') ===-1 &&
      url.indexOf('printPublicPaymentDetail') ===-1 ){
      this.isUrl=true
    }else{
      this.isUrl=false
    }
    this.isLocal = process.env.VUE_APP_LOCAL_DEVELOPMENT
    if(this.screenWidth <= 1400) {
      this.$store.commit('SET_COLLAPSE','open');
    }
    let newMenusArray =homeMenus.concat(platformMenus);
    newMenusArray =newMenusArray.concat(agreementMenus);//
    newMenusArray =newMenusArray.concat(entrustMenus);//
    newMenusArray =newMenusArray.concat(cusMenus);//
    newMenusArray =newMenusArray.concat(consigneeMenus);//
    newMenusArray =newMenusArray.concat(productMenus);//
    newMenusArray = newMenusArray.concat(orgMenus);//
    newMenusArray = newMenusArray.concat(bankDirectMenus);//
    newMenusArray = newMenusArray.concat(paymentMenus);//
    newMenusArray = newMenusArray.concat(reportMenus);//
    newMenusArray =newMenusArray.concat(settingMenus);//
    this.localMenu = newMenusArray
    let menuData = deepCopy(newMenusArray)
    console.log("菜单权限",this.pms);
    const menuSet = new Set(this.pms['0']);
   let authorizedMenu = getAuthorizedMenu(menuData, menuSet);
   authorizedMenu=homeMenus.concat(authorizedMenu);
    this.$store.commit('SET_MENU', authorizedMenu);
   // this.$store.commit('SET_MENU',menuData);
   // this.initWebSocket();
  },
  computed: {
    ...mapGetters(['isCollapse','pms', 'menu','opened','memberInfo', 'accessToken','userInfo']),
    nowTagValue() {
      return setUrlPath(this.$route);
    },
  },
  mounted() {

  },
  methods: {
    savePwd(formName) {
      const set = this.$refs;
      set[formName].validate((valid) => {
        if (valid) {
          // this.buttonLoading = true;
          let password = {
            loginName: this.userInfo.loginName,
            loginPwd: this.formPassword.newPassword,
            oldPwd: this.formPassword.oldPwd,
          };
          changePassword(password)
            .then((res) => {
              this.dialogFormVisible = false;
              if (res.data.code === 0) {
                this.$message.success("修改密码成功,请重新登录");
                this.$refs[formName].resetFields();
                this.formPassword = {};
                const pathStr = `/login`;
                this.$store.dispatch("LogOut").then(() => {
                  this.$router.push({ path: pathStr });
                });
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((fail) => {
              // this.buttonLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    async updPwdMsg(){
      let params = {
        token : this.accessToken
      }
      console.log("========11111111111111111111111111111===",params)
      let isvisible = await updPwdMsg(params);
      if('00900' === isvisible.data.data){
        this.updPwdDialogVisible = true;
      } else {
        this.updPwdDialogVisible = false;
      }
    },
    showCollapse(){
      this.$store.commit("SET_COLLAPSE");
    },
  },
};
</script>
<style lang="scss" scoped>
  .out-menu{
    width: 200px;
    height: calc(100vh - 110px);
    overflow-x: hidden;
    overflow-y:auto;
  }
  .inner-menu{
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 100%;
  }

  /* for Chrome */
  .inner-menu::-webkit-scrollbar {
    display: none;
  }

  #app .el-menu{
    background: #393F6C;
  }
  .home_shousuo{
    text-align: center;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    line-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
  }

/*.sidebar-container.is-active{*/
/*  width: 80px !important;*/
/*}*/
</style>
<style>
  /*.el-menu--popup-right-start{*/
  /*  margin-left: 1px !important;*/
  /*}*/
</style>
