/**
 * 平台菜单
 */

export default [
  {
    label: '支付单',
    icon: 'icon-xieyi',
    id:'13',
    children: [
      {
        label: '支付单明细',
        icon: 'icon-xieyi1',
        path: '/wt/paymentDetail',
        permission: 'pub_5212',
        id:'13-1',
      },
    ],
  },

];

