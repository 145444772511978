/**
 * 平台菜单
 */

export default [
  {
    label: '客户管理',
    icon: 'icon-kehuzx',
    id:'1',
    children: [
      {
        label: '客户管理',
        icon: 'icon-kehuzx',
        path: '/cusm/cusmanager.action',
        permission: 'pub_1201',
        id:'1-1',
      },
      {
        label: '客户线索',
        icon: 'icon-xiansuo',
        path: '/cus/leadsTabs',
        permission: 'pub_5002',
        id:'1-2',
      },
      {
        label: '客户跟进',
        icon: 'icon-yonghu',
        // path: '/wt/detailImportBatchTabs',
        permission: 'pub_5003',
        id:'1-3',
      },
      {
        label: '客户合同',
        icon: 'icon-hetong',
         path: '/cus/contractList',
        permission: 'pub_5004',
        id:'1-4',
      },
      // {
      //   label: '雇员管理',
      //   icon: 'icon-yonghu',
      //   path: '/wt/detailImportBatchTabs',
      //   permission: 'wage_demo_menu',
      //   id:'1-5',
      // }
      // {
      //   label: '客户列表',
      //   icon: 'icon-hetong',
      //   path: '/cus/cusList',
      //   permission: 'pub_5006',
      //   id: '1-6'
      // }
      {
        label: '预扣预缴',
        icon: 'icon-hetong',
        path: '/ykyj/ykyjList',
        permission: 'pub_5015',
        id:'1-7',
      },
      {
        label: '客户配置',
        icon: 'icon-hetong',
        path: '/cus/cusmanagerList',
        permission: 'pub_1201',
        id:'1-8',
      },
      {
        label: '客户转移',
        icon: 'icon-hetong',
        path: '/cus/customerTransfer',
        permission: 'pub_5213',
        id:'1-1',
      },
    ],
  },

];

