/**
 * 平台菜单
 */

export default [
    {
    label: '服务产品',
    icon: 'icon-gongneng',
    id:'5',
    children: [
      {
        label: '服务套餐',
        icon: 'icon-fuwutaocanguanli',
        path: '/product/productPlanList',
        permission: 'pub_5008',
        id:'5-1',
      }
    ],
  },
];

