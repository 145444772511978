/**
 * 系统菜单
 */


export default [
  {
    label: '工作台',
    icon: 'icon-shouye',
    shortcut_icon: "bussiness-man",
    path:'/index',
    id:'1',
  },
]
