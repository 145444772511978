/**
 * 平台菜单
 */

export default [
  {
    label: '系统设置',
    icon: 'icon-xitongshezhi',
    id:'8',
    children: [
      {
        label: '客户信息',
        icon: 'icon-kehuzhongxin',
        path: '/sys/cusInfo.action',
        permission: 'pub_1090',
        id:'8-6',
      },
      {
        label: '基础设置',
        icon: 'icon-gongneng',
        path: '#8-1',
        permission: 'pub_1095',
        id:'8-1',
        children: [
          {
            label: '业务截至时间',
            icon: 'icon-canshupeizhi',
            path: '/businessInfo/businessInfo_businessInfoIndex.action',
            permission: 'pub_1105',
            id:'8-1-1',
          },
          {
            label: '建设建议',
            icon: 'icon-fankui',
            path: '/suggestion/suggestion_suggestionIndex.action',
            permission: 'pub_1158',
            id:'8-1-2',
          },
          {
            label: '征期日历',
            icon: 'icon-kaoqinjiaqi',
            path: '/sys/dataCalendar.action',
            permission: 'pub_1156',
            id:'8-1-3',
          },
          {
            label: '工作日历',
            icon: 'icon-gongzuorili',
            path: '/sys/workCalendar.action',
            permission: 'pub_1155',
            id:'8-1-4',
          },
          {
            label: '政策管理',
            icon: 'icon-gongzuorili',
            path: '/policy/policyList',
            permission: 'pub_1158',
            id:'8-1-4',
          },
          {
            label: '计税区域管理',
            icon: 'icon-gongzuorili',
            path: '/taxArea/taxAreaTabs',
            permission: 'pub_1105',
            id:'8-1-4',
          },
          {
            label: '系统配置',
            icon: 'icon-gongzuorili',
            path: '/sysConf/sysConfig',
            permission: 'pub_1158',
            id:'8-1-4',
          },
          ]
      },
      // {
      //   label: '完税信息',
      //   icon: 'icon-yonghu',
      //   path: '/wt/detailImportBatchTabs',
      //   permission: 'pub_1096',
      //   id:'8-2',
      // },
      {
        label: '工具',
        icon: 'icon-gongju',
        path: '/wageCalculate/wageCalculate_forwardCalculateWage.action',
        permission: 'pub_1097',
        id:'8-3',
      },
      {
        label: '权限管理',
        icon: 'icon-quanxianshezhi',
        path: '#8-4',
        permission: 'pub_1010',
        id:'8-4',
        children: [

          {
            label: '用户管理',
            icon: 'icon-guyuan_gerenxinxi',
            path: '/pms/ciicUserIndex.action',
            permission: 'pub_1015',
            id:'8-4-1',
          },
          {
            label: '角色管理',
            icon: 'icon-guyuan_gangweixinxi',
            path: '/pms/ciicRoleList.action',
            permission: 'pub_1017',
            id:'8-4-2',
          },
          {
            label: '用户授权',
            icon: 'icon-renshiguanli',
            path: '/pms/pmsCiicUserIndex.action',
            permission: 'pub_1019',
            id:'8-4-3',
          },
          {
            label: '已删除用户管理',
            icon: 'icon-zhongzhikehu',
            path: '/pms/ciicUserRevokeList.action',
            permission: 'pub_1021',
            id:'8-4-4',
          },
        ]
      },

      {
        label: '参数管理',
        icon: 'icon-canshupeizhi',
        path: '/parameterInfo/parameterInfo_paramInfoIndex.action',
        permission: 'pub_0129',
        id:'8-5',
      },
      {
        label: '模拟登录',
        icon: 'icon-canshupeizhi',
        path: '/sysMaintenance/imitation_login',
        permission: 'pub_0129',
        id:'8-5',
      },
      {
        label: '公告配置',
        icon: 'icon-canshupeizhi',
        path: '/sysMaintenance/pub_notice',
        permission: 'pub_5222',
        id:'8-5',
      },
      {
        label: '系统维护',
        icon: 'icon-quanxianshezhi',
        path: '#8-6',
        permission: 'pub_1202',
        id:'8-6',
        children: [
          {
            label: '发送失败邮件',
            icon: 'icon-guyuan_gerenxinxi',
            path: '/sysMaintenance/jumpToEmailFailed.action',
            permission: 'pub_1203',
            id:'8-6-1',
          },
        ]
      },
      {
        label: '操作日志审计',
        icon: 'icon-hetong',
        path: '/audit/aduitLog',
        permission: 'pub_5223',
        id:'1-1',
      },
      {
        label: '访问日志审计',
        icon: 'icon-hetong',
        path: '/audit/loginLog',
        permission: 'pub_5224',
        id:'1-1',
      },
    ],
  },

];

