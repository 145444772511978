<template>
    <div class="itemList">
        <div class="left">
            <span class="date">{{data.futureTime|reTime1}}</span>
        </div>
        <div class="right">
            <div><span class="h2">系统公告</span> <span>  <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-shichangcopy"></use>
          </svg><span class="time">{{data.futureTime|reTime}}</span></span></div>
            <p class="content"> {{data.msg}}</p>
        </div>
    </div>
</template>
<script>
  export  default {
     name:"message-business",
     props: {
       status:{
         type: Number,
         default: 0
       },
       data:{
         type: Object,
         default: () =>({
           futureTmie: '',
           statusName: '',
         })
       }
     },
		 filters:{
       reTime(time){
         if(!time){
           return '';
         }
         return time.slice(-8);
       },
       reTime1(time){
         if(!time){
           return '';
         }
         return time.slice(8,10) ;
       }
		 }
  }
</script>
<style lang="scss" scoped>
  .itemList{
    height:74px;
    background:rgba(255,255,255,1);
    border-radius:2px;
    border-left:4px solid rgba(255,188,12,1);
    display:flex;
    padding:10px 0;
		box-sizing: border-box;
		margin-bottom: 10px;
  }
  .date{
    display:block;
    font-size:24px;
    font-weight:500;
    color:rgba(51,51,51,1);
    width:64px;
		text-align: center;
  }
	.icon{
		color: rgba(153,153,153,1);
		font-size: 9px;
		margin-right: 4px;
	}
	.left{
		border-right: 1px solid rgba(243,243,243,1);
		display: flex;
		justify-content: center;
		align-items: center;
	}
  .content{
    white-space: normal;
		font-size:10px;
		font-weight:400;
		color:rgba(153,153,153,1);
		line-height:14px;
  }
 .right{
	 padding: 0 10px;
	 flex-grow: 1;
 }
 .right>div{
	 display: flex;
	 justify-content: space-between;
	 margin-bottom: 6px;
 }
 .h2{
	 font-size:14px;
	font-weight:600;
	color:rgba(51,51,51,1);
	line-height:20px;
 }
 .time{
	 font-size:10px;
		font-weight:400;
		color:rgba(153,153,153,1);
		line-height:14px;
 }
</style>
