<template>
  <div class="menu-wrapper">
    <template v-for="(item,index) in menus" >
      <!--只有一级菜单 -->
      <el-menu-item v-if="onlyOne(item)" :index="filterPath(item.id,item.path)" @click="openPage(item)" :key="item.label">
        <svg class="icon" aria-hidden="true">
          <use :xlink:href="'#'+ item.icon"></use>
        </svg>
        <span slot="title" >{{item.label}}</span>
      </el-menu-item>
      <!--多级菜单-->
      <el-submenu v-if="item.children && item.children.length > 0" :index="filterPath(item.id,item.path)" :key="item.label">
        <!--父级菜单-->
        <template slot="title">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="'#'+ item.icon"></use>
          </svg>
          <span slot="title"  :class="{'el-menu--display':isCollapse}">{{item.label}}</span>
        </template>
        <!--子级菜单-->
        <template v-for="(child,cindex) in item.children" >
          <sidebar-item v-if="child.children && child.children.length > 0" :menuData="[child]" :key="cindex" :isCollapse="isCollapse"></sidebar-item>
          <el-menu-item v-else :index="filterPath(child.id,child.path)" @click="openPage(child)"  :key="cindex">
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="'#'+ child.icon"></use>
            </svg>
            <span slot="title" >{{child.label}}</span>
          </el-menu-item>
        </template>
      </el-submenu>
    </template>
  </div>
</template>
<script>
export default {
  name: 'SidebarItem',
  data() {
    return {
      menus: null,
    };
  },
  inject: ['reload'],
  props: {
    menuData: {
      type: Array,
    },
    isCollapse: {
      type: Boolean,
    },
  },
  created() {
    this.menus = this.menuData;
    console.log('菜单',this.menuData)
  },
  mounted() {
  },
  computed: {
    onlyOne() {
      const that = this;
      return function (item) {
        if (!item.children || item.children.length === 0) {
          return true;
        }
      };
    },
  },
  methods: {
    filterPath(id,path) {
        // console.log('测试', path == null ? `${index}` : path)
        return path == null ? id : path;

    },
    //判断两个对象值相不相等
    isEqual (a, b) {
      const classNameA = toString.call(a)
      const classNameB = toString.call(b)
      // 如果数据类型不相等，则返回false
      if (classNameA !== classNameB) {
        return false
      } else {
        // 如果数据类型相等，再根据不同数据类型分别判断
        if (classNameA === '[object Object]') {
          for (let key in a) {
            if (!this.isEqual(a[key], b[key])) return false
          }
          for (let key in b) {
            if (!this.isEqual(a[key], b[key])) return false
          }
          return true
        } else if (classNameA === '[object Array]') {
          if (a.length !== b.length) {
            return false
          } else {
            for (let i = 0, len = a.length; i < len; i++) {
              if (!this.isEqual(a[i], b[i])) return false
            }
            return true
          }
        } else if (classNameA === '[object Function]') {
          return a.toString() === b.toString()
        } else {
          return Object.is(a, b)
        }
      }
    },
    openPage(item) {
      // 清除记录的tab  name
      if(sessionStorage.getItem("tabIndex")){
        sessionStorage.removeItem("tabIndex")
      }

      let path = item.path;
      let params = "";
      // 处理路由URL带参数的菜单
      if(typeof item.params === 'function') {
          params = item.params(this.$store);
          if(item.path && item.path.indexOf('/:') !== -1){
              const pathItemArr = item.path.split('/');
              pathItemArr.forEach((pathItem) => {
                  if(pathItem.indexOf(':') === 0) {
                      const paramName = pathItem.substring(1);
                      path = path.replace(pathItem,params[paramName])
                  }
              })
          }
      }
      console.log('我的路由',path,item.query, this.$route.query)
      if (item.path === this.$route.path) {
        this.reload();
        if(item.query !=undefined ){
            this.$router.push({
                path: path,
                query: item.query,
            });
        }
      } else {
        if(item.query !=undefined ){
            this.$router.push({
                path: path,
                query: item.query,
            });
        }else{
          this.$router.push({
            path: path,
          });
        }
          // this.$router.push({
          //   path: path,
          //   query: item.query,
          // });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .el-menu-item{
    background: #1C2C52 !important;
  }
  .el-menu-item.is-active {
    color: #409EFF !important;
  }
  .el-menu-item:hover{
    background: #2C467E !important;
  }
  .el-menu-item{
    .icon{
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: 18px;
      vertical-align: middle;
      color: #ffffff;
    }
  }
  .el-submenu{
    .icon{
      vertical-align: middle;
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
    }
  }
</style>


