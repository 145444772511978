/**
 * 平台菜单
 */

export default [

  {
    label: '委托业务',
    icon: 'icon-weituo',
    id:'2',
    children: [
      {
        label: '薪资导入',
        icon: 'icon-kehuzhangdan',
        path: '/wt/detailImportBatchTabs',
        permission: 'pub_1035',
        id:'2-1',
      },
      {
        label: '补贴',
        icon: 'icon-kehuzhangdan',
        path: '/wt/buTieTabs',
        permission: 'pub_1080',
        id:'2-2',
      },
      {
        label: '社保导入',
        icon: 'icon-shebaochaxun',
        path: '/entrust/socialSecurityImportList',
        permission: 'pub_5005',
        id:'2-3',
      },
      {
        label: '支付确认',
        icon: 'icon-fafangpici',
        path: '/entrust/distributionBatchIndex',
        permission: 'pub_5010',
        id:'2-13',
      },
      {
        label: '客户账单',
        icon: 'icon-zhangdan',
        path: '/entrust/paymentBills',
        permission: 'pub_5006',
        id:'2-4',
      },
      {
        label: '支出单管理',
        icon: 'icon-zhichudan2',
        path: '/consignor/payoutBillsIndex.action',
        permission: 'pub_1030',
        id:'2-5',
      },
      {
        label: '委托批次管理',
        icon: 'icon-icon-test',
        path: '/consignor/batchIndex.action',
        permission: 'pub_1040',
        id:'2-6',
      },
      // {
      //   label: '退票管理',
      //   icon: 'icon-tuipiao',
      //   path: '/consignor/payoutBills_packedRTicket.action',
      //   permission: 'pub_1125',
      //   id:'2-7',
      // },
      {
        label: '退票管理',
        icon: 'icon-tuipiao',
        path: '/wt/packedRTicketTabs',
        permission: 'pub_1125',
        id:'2-7',
      },
      // {
      //   label: '导入模板更名',
      //   icon: 'icon-xianlugengming',
      //   path: '/trusted/wageTemplate_forwardImportTemplateOfOrg.action',
      //   permission: 'pub_1034',
      //   id:'2-8',
      // },
      {
        label: '导入模板更名',
        icon: 'icon-xianlugengming',
        path: '/entrust/importTemplateRename',
        permission: 'pub_1034',
        id:'2-8',
      },
      /*{
        label: '工资条查询',
        icon: 'icon-gongzitiao',
        path: '/wageBills/wageBillsIndex.action',
        permission: 'pub_1164',
        id:'2-9',
      },*/
      {
        label: '工资条查询',
        icon: 'icon-gongzitiao',
        path: '/wageBills/wageBillsPage',
        permission: 'pub_1164',
        id:'2-9',
      },
      {
        label: '委托历史记录查询',
        icon: 'icon-lishijilu',
        path: '/consignee/batchAction_historyBizTypeIndex.action?operateFlag=MTAwMA--',
        permission: 'pub_1140',
        id:'2-10',
      },
      {
        label: '汇款差额查询',
        icon: 'icon-zhuankuan',
        path: '/consignor/differenceIndex.action',
        permission: 'pub_1168',
        id:'2-11',
      },
      {
        label: '专项导入',
        icon: 'icon-zhuanxiangshenhe',
        path: '/special/trustedImportSpecial_special.action',
        permission: 'pub_1169',
        id:'2-12',
      },
      {
        label: '平均工资',
        icon: 'icon-zhuanxiangshenhe',
        path: '/wt/averagewage',
        permission: 'pub_5016',
        id:'2-13',
      },
      {
        label: '委外服务查询',
        icon: 'icon-zhuanxiangshenhe',
        path: '/wt/entrustPrice',
        permission: 'pub_5214',
        id:'2-14',
      },
    ],
  },

];

