/**
 * 平台菜单
 */

export default [
  {
    label: '银企直联',
    icon: 'icon-xieyi',
    id:'12',
    children: [
      {
        label: '银企直联相关',
        icon: 'icon-xieyi1',
        path: '/wt/bankDirectDetail',
        permission: 'pub_5211',
        id:'12-1',
      },
    ],
  },

];

