/**
 * 平台菜单
 */

export default [
  {
    label: '统计报表',
    icon: 'icon-tongjichaxun',
    id:'6',
    children: [
      {
        label: '客户对比表',
        icon: 'icon-chaxuntongji',
        path: '/rpt/rptAction_cusCompareIndex.action',
        permission: 'pub_1065',
        id:'6-1',
      },
      //对接薪税初，数据排查使用，已废弃
      /*{
        label: '支出单批次',
        icon: 'icon-chaxuntongji',
        path: '/rpt/payoutBillsBatch',
        permission: 'pub_1065',
        id:'6-6',
      },*/
      {
        label: '服务费收入与成本对比',
        icon: 'icon-feiyongtongji',
        path: '/rpt/rptAction_serviceFeeCompare.action',
        permission: 'pub_1070',
        id:'6-2',
      },
      {
        label: '服务费核对表',
        icon: 'icon-shujuhedui',
        path: '/rpt/rptAction_serviceFeeCheckIndex.action',
        permission: 'pub_1075',
        id:'6-3',
      },
      {
        label: '委托记录统计',
        icon: 'icon-tongji',
        path: '/rpt/rptAction_consignorRecordList.action',
        permission: 'pub_1145',
        id:'6-4',
      },
      {
        label: '客户分润',
        icon: 'icon-tongji1',
        path: '/wt/socialSecurityImportList',
        permission: 'pub_5007',
        id:'6-5',
      },
    ],
  },

];

