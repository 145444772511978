/**
 * 平台菜单
 */

export default [
   {
    label: '服务协议',
    icon: 'icon-xieyi',
    id:'4',
    children: [
      {
        label: '薪酬协议',
        icon: 'icon-xieyi1',
        permission: 'pub_1025',
        id:'4-1',
        children: [
          {
            label: '协议管理',
            icon: 'icon-xieyi1',
            path: '/agreement/agreement_agreementIndex.action',
            permission: 'pub_1025',
            id:'4-1-1',
          },
          {
            label: '申请管理',
            icon: 'icon-qingdan2',
            path: '/agreementInfo/agreementInfo_applyIndex.action',
            permission: 'pub_1005',
            id:'4-1-2',
          },
        ]
      },
      {
        label: '财税协议',
        icon: 'icon-shenpi',
        permission: 'pub_5220',
        id:'4-2',
        children: [
          {
            label: '协议管理',
            icon: 'icon-xieyi1',
            path: '/agree/finTaxAgreementIndex',
            permission: 'pub_5220',
            id:'4-2-1',
          },
          {
            label: '申请管理',
            icon: 'icon-qingdan2',
            path: '/agree/finTaxAgreementApplyIndex',
            permission: 'pub_5221',
            id:'4-2-2',
          },
        ]
      },

      {
        label: '委托价格审批',
        icon: 'icon-trial',
        path: '/agreement/agreement_agreementPriceIndex.action',
        permission: 'pub_1020',
        id:'4-3',
      },
      {
        label: '对公委托价格表',
        icon: 'icon-shenpi',
        path: '/priceInfo/priceInfo_getAllPriceInfoIndex.action',
        permission: 'pub_1120',
        id:'4-4',
      },
    ],
  },

];

