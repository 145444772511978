

<template>
  <div class="logo">
        <span class="logo_title is-bold is-img"  @click="toindex()" style="cursor:pointer;">

          <transition name="fade">
            <template v-if="isCollapse" >
              <img src="@/assets/img/logo1.png" key="0" width="39" height="24" />
              <span>logo</span>
            </template>
            <template v-else >
              <img   src="@/assets/img/logo2.png" width="165" height="24" />
              <span>展开logo</span>

            </template>
          </transition>
        </span>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'logo',
  data() {
    return {
      logoPath: '',
      logoTextPath:'',
    };
  },
  props: ['isCollapse'],
  created() {

  },
  computed: {
    ...mapGetters(['website', 'loginType']),
  },
  methods: {
    // 回到首页
    toindex() {
      this.$router.push({path: '/index'});
    },
  },
};
</script>
<style scoped="scoped" lang="scss">
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /*width: 290px;*/
  height: 64px;
  line-height: 64px;
  /*background: #002140;*/
  color: #fdfdfd;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  box-sizing: border-box;
}
.logo_title {
  /*padding: 10px 0 0 0;*/

  color: white;
  font-size: 30px;
  &.is-bold {
    font-weight: 700;
  }
}

.is-img {
  /*position: absolute;*/
  /*top: 10px;*/
  /*left: 13px;*/
  display:flex;
  align-items: center;
  justify-content: center;
}
.logo_subtitle {
  font-size: 16px;
  padding-top: 5px;
}

</style>
