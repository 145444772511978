import request from '@/router/axios'

export function getMyInfo() {
  return request({
    url: '/user/user/myinfo',
    method: 'get',
  });
}

export function changePassword(obj) {
  return request({
    url: "pub-user/changePassword",
    method: 'post',
    data: obj
  })
}
/** 模拟登录-查询用户列表 */
export function searchUserList(obj) {
  return request({
    url: "pub-user/search-user-list",
    method: 'post',
    data: obj
  })
}




