import request from '@/router/axios';
const urlPrefix = ''

/**
 * 获取数据
 * @param obj
 */
export  function getHomeNum(obj) {
  return request({
    url:urlPrefix+ '/home/handleNum',
    method: 'get'
  })
}

export function visible6W(obj) {
  return request({
    url:urlPrefix+ '/home/visible6W',
    method: 'post',
    data:obj
  })
}

export function updPwdMsg(obj) {
  return request({
    url:urlPrefix+ '/home/updPwdMsg',
    method: 'post',
    data:obj
  })
}

export function setUnvisible6W(obj) {
  return request({
    url:urlPrefix+ '/home/unvisible6W',
    method: 'post',
    data:obj
  })
}

/**
 */
export  function getOrg(obj) {
  return request({
    url:urlPrefix+ '/home/getOrg/'+obj,
    method: 'get'
  })
}
export  function  getUserReport() {
  return request({
    url:urlPrefix+ '/home/getUserReport',
    method: 'get'
  })
}
export  function endTax(obj) {
  return request({
    url:urlPrefix+ '/home/endTax',
    method: 'post',
    data:obj
  })
}
export  function getPayTime() {
  return request({
    url:urlPrefix+ '/home/getPayTime',
    method: 'get'
  })
}


export function visibleNotice(obj) {
  return request({
    url:urlPrefix+ '/home/visibleNotice',
    method: 'post',
    data:obj
  })
}







