/**
 * 平台菜单
 */

export default [
  {
    label: '机构',
    icon: 'icon-xieyiguanli1',
    id:'7',
    children: [
      {
        label: '机构管理',
        icon: 'icon-xieyiguanli1',
        path: '/orgInfo/orgInfo_orgInfoIndex.action',
        permission: 'pub_1085',
        id:'7-1',
      },
     /* {
        label: '对公余额查询',
        icon: 'icon-yuechaxun',
        path: '/priceInfo/priceInfo_getBalanceInfoIndex.action',
        permission: 'pub_1130',
        id:'7-1',
      }*/
    ],
  },

];

