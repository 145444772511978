<template>
  <div class="top">
    <div class="top-button is-left">
      <!--<div class="logo">-->
        <!--<img v-if="dark" src="@/assets/img/logo_w.png" alt="">-->
        <!--<img v-else src="@/assets/img/logo_b.png" alt="">-->
      <!--</div>-->
      <!--<div class="super-navs">-->
      <!--<div class="super-nav" :class="{'is-active':home}" @click="showCollapse">-->
      <!--<svg class="icon" aria-hidden="true" >-->
      <!--<use xlink:href="#icon-gongzuotai"></use>-->
      <!--</svg>-->
      <!--<p>工作台</p>-->
      <!--</div>-->
      <!--<div class="super-nav" :class="item.active?'is-active':''" v-for="(item,index) in menus" v-bind:key="item.id" @click="openMenu(item.id,index)">-->
      <!--<svg class="icon" aria-hidden="true" >-->
      <!--<use xlink:href="#icon-gongzuotai"></use>-->
      <!--</svg>-->
      <!--<p>{{item.label}}</p>-->
      <!--</div>-->
      <!--&lt;!&ndash;<div class="super-nav">&ndash;&gt;-->
      <!--&lt;!&ndash;<svg class="icon" aria-hidden="true" >&ndash;&gt;-->
      <!--&lt;!&ndash;<use xlink:href="#icon-guyuanzhongxin"></use>&ndash;&gt;-->
      <!--&lt;!&ndash;</svg>&ndash;&gt;-->
      <!--&lt;!&ndash;<p>雇员中心</p>&ndash;&gt;-->
      <!--&lt;!&ndash;</div>&ndash;&gt;-->
      <!--&lt;!&ndash;<div class="super-nav">&ndash;&gt;-->
      <!--&lt;!&ndash;<svg class="icon" aria-hidden="true" >&ndash;&gt;-->
      <!--&lt;!&ndash;<use xlink:href="#icon-yewubanli"></use>&ndash;&gt;-->
      <!--&lt;!&ndash;</svg>&ndash;&gt;-->
      <!--&lt;!&ndash;<p>业务办理中心</p>&ndash;&gt;-->
      <!--&lt;!&ndash;</div>&ndash;&gt;-->
      <!--&lt;!&ndash;<div class="super-nav">&ndash;&gt;-->
      <!--&lt;!&ndash;<svg class="icon" aria-hidden="true" >&ndash;&gt;-->
      <!--&lt;!&ndash;<use xlink:href="#icon-gonggongchaxun"></use>&ndash;&gt;-->
      <!--&lt;!&ndash;</svg>&ndash;&gt;-->
      <!--&lt;!&ndash;<p>公共查询</p>&ndash;&gt;-->
      <!--&lt;!&ndash;</div>&ndash;&gt;-->
      <!--&lt;!&ndash;<div class="super-nav">&ndash;&gt;-->
      <!--&lt;!&ndash;<svg class="icon" aria-hidden="true" >&ndash;&gt;-->
      <!--&lt;!&ndash;<use xlink:href="#icon-xitongbaobiao"></use>&ndash;&gt;-->
      <!--&lt;!&ndash;</svg>&ndash;&gt;-->
      <!--&lt;!&ndash;<p>系统报表</p>&ndash;&gt;-->
      <!--&lt;!&ndash;</div>&ndash;&gt;-->
      <!--</div>-->
      <!--<div class="tags-breadcrumb ciic-cursor-pointer">-->
        <!--<svg class="icon collapse" aria-hidden="true" @click="showCollapse">-->
          <!--<use :xlink:href="isCollapse?'#icon-home_shousuo_icon':'#icon-home_shousuo_h_icon'"></use>-->
        <!--</svg>-->
      <!--</div>-->
    </div>
    <!--<div class="is-center">-->
      <!--<el-input-->
        <!--class="search"-->
        <!--placeholder=""-->
        <!--suffix-icon="el-icon-search"-->
        <!--v-model="search">-->
      <!--</el-input>-->
    <!--</div>-->
    <div class="top-button is-right">
      <!--<el-tooltip class="item" effect="dark" content="消息" placement="bottom">-->
      <!-- 消息暂时不做-->
      <!-- <div class="message">
        <span class="top-item ciic-cursor-pointer pifu">
          <el-badge :value="msgPage.total" class="item">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tongzhi"></use>
            </svg>
          </el-badge>
        </span>
        <div class="message-title">
          <div class="msg-top">
            <p>未读消息</p>
            <el-link type="primary" @click="toMsg">进入消息中心</el-link>
          </div>
          <div class="msg-list" >

            <div v-for="(msgs,i) in msgList1" :key="i" >
              <h2>{{i}}</h2>
              <div v-for="(msg,index) in msgs" :key="index" @click="alreadyRead(msg.id)">
                  <business v-if="msg.templateId == 1" :data="msgContent(msg.content,msg,templateId)" :status="msg.status">

                  </business>
                  <toDo v-if="msg.templateId == 2" :data="msgContent(msg.content,msg,templateId)" :status="msg.status">

                  </toDo>
                  <approval v-if="msg.templateId == 3" :data="msgContent(msg.content,msg,templateId)" :status="msg.status">

                  </approval>
                  <system v-if="msg.templateId == 4" :data="msgContent(msg.content,msg,templateId)" :status="msg.status">

                  </system>
              </div>

            </div> -->

            <!-- <div v-for="(msg,i) in msgList" >
              <business v-if="msg.templateId == 1" :data="msgContent(msg.content,msg,templateId)" :status="msg.status">

              </business>
              <toDo v-if="msg.templateId == 2" :data="msgContent(msg.content,msg,templateId)" :status="msg.status">

              </toDo>
              <approval v-if="msg.templateId == 3" :data="msgContent(msg.content,msg,templateId)" :status="msg.status">

              </approval>
              <system v-if="msg.templateId == 4" :data="msgContent(msg.content,msg,templateId)" :status="msg.status">

              </system>
            </div>

            </div>
          </div>
        </div> -->
      <!--</el-tooltip>-->
      <!--<el-tooltip class="item" effect="dark" content="换肤" placement="bottom">-->
        <!--<span class="top-item ciic-cursor-pointer pifu" @click="huanfu">-->
          <!--<svg class="icon" aria-hidden="true">-->
            <!--<use xlink:href="#icon-pifu"></use>-->
          <!--</svg>-->
        <!--</span>-->
      <!--</el-tooltip>-->
      <!--<el-tooltip class="item" effect="dark" content="消息" placement="bottom">
        <span class="top-item ciic-cursor-pointer pifu">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-tongzhi"></use>
          </svg>
        </span>
      </el-tooltip>-->

      <!--<el-tooltip class="item" effect="dark" content="锁屏" placement="bottom">-->
      <!--<span class="top-item ciic-cursor-pointer" >-->
      <!--<top-lock></top-lock>-->
      <!--</span>-->
      <!--</el-tooltip>-->
      <!--<el-tooltip class="item" effect="dark" :content="isFullScren?'退出全屏':'全屏'" placement="bottom">-->
      <!--<span class="top-item quanping ciic-cursor-pointer">-->
      <!--<svg class="icon" aria-hidden="true" @click="handleScreen">-->
      <!--<use :xlink:href="isFullScren?'#icon-tuichuquanping':'#icon-quanping'"></use>-->
      <!--</svg>-->
      <!--</span>-->
      <!--</el-tooltip>-->
      <el-tooltip class="item" effect="dark" content="当前机构" placement="bottom" style="margin: auto">
        <span class="top-item ciic-cursor-pointer" @click="orgChange">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jigou"></use>
          </svg>
        </span>
      </el-tooltip>
      <el-dropdown>
        <span class="ciic-cursor-pointer">
          <span>{{orgName}}</span>
         {{userInfo.mobile}}
          <svg class="icon xiala" aria-hidden="true">
            <use xlink:href="#icon-xiala"></use>
          </svg>
        </span>
        <el-dropdown-menu slot="dropdown">
          <div v-for="item in relOrgList">
            <el-dropdown-item @click.native="handleLogin(item)" >{{item.orgName}}</el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <el-tooltip class="item" effect="dark" content="操作说明" placement="bottom">
        <span class="top-item ciic-cursor-pointer" @click="tohelp">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-shuomingshu"></use>
          </svg>
        </span>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="用户头像" placement="bottom">
        <span class="ciic-cursor-pointer pifu" @click="handleUserClick">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-yonghu1"></use>
          </svg>
        </span>
      </el-tooltip>
      <el-dropdown>
        <span class="ciic-cursor-pointer">
          <span>{{currentUser}}</span>
         {{userInfo.mobile}}
          <svg class="icon xiala" aria-hidden="true">
            <use xlink:href="#icon-xiala"></use>
          </svg>
        </span>
        <el-dropdown-menu slot="dropdown">
<!--          <el-dropdown-item>-->
<!--            <span @click="handleUserClick">基本信息设置</span>-->
<!--          </el-dropdown-item>-->
          <el-dropdown-item @click.native="logout" >退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import { fullscreenToggel, listenfullscreen } from "@/util/util";
  import topLock from "./top-lock";
  import cusMenus from '@/menus/cus/cus';
  import entrustMenus from '@/menus/entrust/entrust';
  import agreementMenus from '@/menus/agreement/agreement';
  import consigneeMenus from '@/menus/consignee/consignee';
  import productMenus from '@/menus/product/product';
  import orgMenus from '@/menus/org/org';
  import reportMenus from '@/menus/report/report';
  import settingMenus from '@/menus/setting/setting';
  import bankDirectMenus from '@/menus/bank_direct/bank_direct';
  import paymentMenus from '@/menus/payment/payment';
  import platformMenus from "@/menus/demo/demo";
  import approval from "@/template/msg/approval";
  import business from "@/template/msg/business";
  import system from "@/template/msg/system";
  import toDo from "@/template/msg/toDo";
  import { queryUserListPage,updateBatchMsgStatus } from "@/api/msg/msg";
  import cross from "@/util/cross";

  export default {
    components: { topLock,approval,business,system,toDo },
    name: "top",
    inject: ['reload'],
    data() {
      return {
				yearMoneth:'', //数组当前年月
        dark:false,
        dialogFormMyservice: false,
        isLocal: null,
        menus: null,
        localMenu: null,
        home: true, //工作台
        search: "",
        currentUser:'',
        orgName:'',
        msgList: [],
        relOrgList: [],
        updateStatus:{
          ids: [],
          status: 23
        },
        msgPage: {
          limit: 50,
          page: 1,
          total: 0,
          status:22
        },
        loginForm: {
				  personId: '',
          username: '',
          password: '',
          isSwitch:'false',
        },
      };
    },
    filters: {},
    created() {
      // this.$nextTick(function () {
      this.loadPage();
       //this.getCurrentLoginUser()
      //this.queryMsg();
     // this.initWebSocket();
    // })
  },
    mounted() {
        if(localStorage.Theme == "light-theme"){
          this.dark = false;
        }else{
          this.dark = true;
        }
      this.$nextTick(function() {

        //   this.menus = this.isLocal == 'true' ? this.localMenu : this.menu
        //   console.log("this.menus1 ")
        //   console.log(this.menus)
        // if(this.opened.length == 0){
        //   this.showCollapse()
        // }else{
        //   this.opened.push(sessionStorage.opened);
        //     this.$set(this.menus[this.opened[0] - 1],'active',true)
        //     this.$store.commit('SET_COLLAPSE','open');
        //   }
      });
      // listenfullscreen(this.setScreen);
      this.isLocal = process.env.VUE_APP_LOCAL_DEVELOPMENT;
      var that = this;
      // 其他页面调用本页面的方法
      cross.$on('resHomeMsg', function (msg) {
        that.queryMsg();

      })
    },
    computed: {
			msgList1(){

				 let obj = {};
				 obj = {'2019-03':[{futureTime:'2019-03-04 19:00:00',templateId:1,msg:'测试',content:'11234'},
				 {futureTime:'2019-03-04 19:00:00',templateId:2,msg:'测试',content:'11234'},
				 {futureTime:'2019-03-04 19:00:00',templateId:3,msg:'测试',content:'11234'},
				 {futureTime:'2019-03-04 19:00:00',templateId:4,msg:'测试',content:'11234'}]}  //todo 模拟假数据
// 				 this.msgList.map((msg)=>{
// 					 let content = this.msgContent(msg.content);
// 					 let YM = content.futureTime.substring(0,7);
// 					 if(this.yearMoneth!=YM){
//
// 						 this.yearMoneth = YM;
//
//
// 						 obj[this.yearMoneth] = [];
// 					 }
//            obj[this.yearMoneth].push(msg);
// 				 })
        this.yearMoneth="";
				 return obj

			},
      ...mapGetters(["isFullScren", "isCollapse", "userInfo", "menu", "opened","memberInfo"])
    },
    methods: {
      loadPage(){
          let newMenusArray = platformMenus
          newMenusArray =newMenusArray.concat(cusMenus);//委托菜单
          newMenusArray =newMenusArray.concat(entrustMenus);//委托菜单
          newMenusArray =newMenusArray.concat(agreementMenus);//委托菜单
          newMenusArray =newMenusArray.concat(consigneeMenus);//委托菜单
          newMenusArray =newMenusArray.concat(productMenus);//委托菜单
          newMenusArray =newMenusArray.concat(orgMenus);//委托菜单
          newMenusArray =newMenusArray.concat(reportMenus);//委托菜单
          newMenusArray =newMenusArray.concat(settingMenus);//委托菜单
          newMenusArray =newMenusArray.concat(bankDirectMenus);//委托菜单
          newMenusArray =newMenusArray.concat(paymentMenus);//委托菜单

          this.localMenu = this.menu;
          console.log(this.localMenu,'头部菜单');
          this.currentUser= this.userInfo.name;
          this.orgName= this.userInfo.orgName;
          this.relOrgList = this.userInfo.relOrgList;
      },
      // 设置消息为已读
      alreadyRead(id){
        this.updateStatus.ids.push(id);
        updateBatchMsgStatus(this.updateStatus).then(res=>{
          console.log("修改消息已读反回的结果",res)
          if(res.data.data){
            let inx = this.msgList.findIndex((item) => {
              return item.id = id
            })
            this.msgPage.total--;
            this.msgList.splice(inx,1)
            this.$message.success('标记已读成功');
          }else {
            this.$message.error('标记已读失败');
          }
        })

      },
      initWebSocket() {
        this.connection();
      },
      //上线
      connection(){
        //console.log("111用户信息：",this.userInfo);
        console.log("111用户信息member：",this.memberInfo);
        console.log("111用户信息user：",this.user);
        console.log("111用户信息tenantInfo：",this.tenantInfo);
        console.log("111用户信息clientInfo：",this.clientInfo);
        console.log("111用户信息pms：",this.pms);
        console.log("process.env.VUE_PUB_BASE_URL：",process.env.VUE_PUB_BASE_URL);
        let baseUrl = process.env.VUE_APP_BASE_URL;
        //建立连接对象
        let socket = new SockJS(baseUrl+"/message/ws");
        // debugger
        // 获取STOMP子协议的客户端对象
        this.stompClient = Stomp.over(socket);
        //将socket对象存在session里
        // sessionStorage.setItem("stompClient", this.stompClient);
        this.stompClient.debug = null
        // 定义客户端的认证信息,按需求配置
        let headers = {
          'Authorization': 'Bearer ' + this.accessToken
        };
        // 现在用用户id去获取消息后期要改成成员id

        let userId = this.memberInfo.id;
        // 向服务器发起websocket连接
        this.stompClient.connect(headers,() => {
          console.log("连接websockedddddddddddddddddddddddt成功")
          this.stompClient.subscribe('/user/'+userId+'/message', (msg) => {
            // msg.body存放的是服务端发送给我们的信息
            console.log('广播成功dasdssssssssss', msg)
            console.log('广播成功', msg.body)
            console.log(this.$route.path)
            /*if(this.$route.path != '/index'){
              this.$notify.info({
                title: '消息',
                message: '您有新的消息，请注意查收'
              });
            }*/
            //把实时收到的消息放入消息提醒列表中
            let msgObj={};
            msgObj.content = msg.body;

            msgObj.templateId =msg.body.substring(0,1);;
            msgObj.status=22;
            this.msgList.push(msgObj);
            this.$set(this.msgList,msgObj);
            this
            this.msgPage.total = this.msgPage.total+1;
            console.log("推送的消息",this.msgList,this.msgPage.total);
            this.resetSetItem('sockedMsgContent', msg.body);
          },headers);
        }, (err) => {
          // 连接发生错误时的处理函数
          console.log('失败')
          console.log(err);
        });
      },
      // openMenu(id,index){
      //   this.$store.commit('SET_COLLAPSE','open');
      //   let opened = [];
      //   opened.push(id)
      //   this.$store.commit('SET_OPENED',opened);
      //   sessionStorage.setItem('opened',opened);
      //   //没有子菜单的一级菜单单独处理，直接跳转
      //   console.log(this.menus[id-1].hasOwnProperty('children'))
      //   if(!this.menus[id-1].hasOwnProperty('children')){
      //     this.$router.push({ path:this.menus[id-1].path });
      //   }
      //
      //   this.home = false;
      //   for(var i = 0;i < this.menus.length;i++){
      //     if(i == index){
      //       this.$set(this.menus[i],'active',true)
      //     }else{
      //       this.$set(this.menus[i],'active',false)
      //     }
      //   }
      // },
      //查询消息
      queryMsg(){
        queryUserListPage(this.msgPage).then(res=>{
          console.log("加载消息：=",res)
          this.msgList = res.data.records;
          this.msgPage.total = res.data.total;
          console.log("加载消息：msgList=",this.msgList)


        });
      },
      msgContent(content,msg,templateId){
				return msg;  //todo  返回之前的数据


        let arr =content.trim().split(/\s+/)
        let subString = content.slice(content.indexOf(' ') + 1)
        if(content && content!="null"){
        let obj = {}
        obj.templateId = JSON.parse(arr[0])
        obj.content = JSON.parse(subString)


          //console.log("转化后的内容：",content);
          //let parse = JSON.parse(content);
          content.indexOf()
          let t = content.split(templateId);
          // console.log("转化后的内容：",t);
          return JSON.parse(subString);
        }
      },
      getCurrentLoginUser(){
          let memberId = this.memberInfo.id;
      },
      showCollapse() {
        this.$store.commit("SET_COLLAPSE","open");
        // this.home = true;
        // for(var i = 0;i < this.menus.length;i++){
        //     this.$set(this.menus[i],'active',false)
        // }
        // this.$store.commit('SET_COLLAPSE','close');
        // this.$router.push({ path: '/index' });
        // this.$store.commit('SET_OPENED',0);
        // sessionStorage.setItem('opened',0);
      },
      handleScreen() {
        fullscreenToggel();
      },
      huanfu(){
        if ( localStorage.getItem("Theme") ==  "light-theme" ){
          localStorage.setItem('Theme', "dark-theme");
          localStorage.setItem('Color', "#ffffff");
          this.$store.commit('CHANGECOLOR')
          this.dark = true;
          document.getElementsByTagName("body")[0].className=localStorage.getItem("Theme");

        }else{
          localStorage.setItem('Theme', "light-theme");
          localStorage.setItem('Color', "#000000");
          this.$store.commit('CHANGECOLOR')
          this.dark = false;
          document.getElementsByTagName("body")[0].className=localStorage.getItem("Theme");
        }
      },
      setScreen() {
        this.$store.commit("SET_FULLSCREN");
      },
      tohelp() {
        this.$router.push({ path: "/help" });
      },
      orgChange(){

      },
      logout() {
        this.$confirm("将要退出系统, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {

					//todo注释退出方法 ,直接跳转
				//this.$router.push({ path: "/login" });
          this.$store.dispatch("LogOut").then(() => {
            // 清除记录的tab  name
            if(sessionStorage.getItem("tabIndex")){
              sessionStorage.removeItem("tabIndex")
            }
            this.$router.push({ path: "/login" });
          });
          this.$store.commit("SET_OPENED", []);
          this.showCollapse();
          sessionStorage.setItem("opened", []);
        });
      },
        handleLogin(item) {
            //todo  登录接口,目前直接跳转到首页(切换用户)
            this.loginForm.personId = item.personId;
            this.loginForm.username = item.userName;
            this.loginForm.password = item.passWord;
            this.loginForm.isSwitch = 'true';
            this.$store.dispatch('LoginByUsername', this.loginForm).then(
                (res) => {
                    if (res.data.data.success == "false") {
                        this.loginLoading = false;
                        this.$message.error(res.data.data.msg);
                        // 刷新一下验证码
                        this.reImageCode();
                        this.loginForm.isSwitch = 'false';
                    } else {
                        this.$store.state.impData.cusId = '';
                        this.$store.state.impData.billMonth = '';
                        this.$router.push({path: '/index'});
                        this.$message.success('切换用户成功');
                        this.loginForm.isSwitch = 'false';
                        this.$emit('reloadSider');
                        this.loadPage();
                        this.reload();
                    }
                }
            ).catch(
                (error) => {
                    this.$message.error('切换用户失败');
                    if (error.data && error.data.data.msg) {
                        this.$message.error(error.data.data.msg);
                    }
                    this.loginForm.validCode = '';
                    this.loginForm.isSwitch = 'false';
                })
        },
      /**
       * 处理设置的点击事件
       * @param item
       */
      handleSettingClick(item) {
        // 清除记录的tab  name
        if(sessionStorage.getItem("tabIndex")){
          sessionStorage.removeItem("tabIndex")
        }

        if (item.path) {
          if (item.path === this.$route.path) {
            this.reload();
          } else {
            let path = item.path;
            let params = "";
            // 处理路由URL带参数的菜单
            if (typeof item.params === "function") {
              params = item.params(this.$store);
              if (item.path && item.path.indexOf("/:") !== -1) {
                const pathItemArr = item.path.split("/");
                pathItemArr.forEach((pathItem) => {
                  if (pathItem.indexOf(":") === 0) {
                    const paramName = pathItem.substring(1);
                    path = path.replace(pathItem, params[paramName]);
                  }
                });
              }
            }
            this.$router.push({
              path: path,
              query: item.query
            });
          }
        } else {
          item.handle(this);
        }
      },
      /**
       * 处理用户信息的点击事件
       * @param item
       */
      handleUserClick() {
        // 清除记录的tab  name
        if(sessionStorage.getItem("tabIndex")){
          sessionStorage.removeItem("tabIndex")
        }
        this.$router.push({path:"/person/edit"});
      },
      toMsg(){
        // 清除记录的tab  name
        if(sessionStorage.getItem("tabIndex")){
          sessionStorage.removeItem("tabIndex")
        }
        this.$router.push("/cus/msgTabs");
      }
    },
}
</script>
<style>

</style>
<style lang="scss" scoped>
  .collapse{
    opacity: 0.5;
  }

  .logo {
    padding-left: 20px;
    margin-right: 50px;
  }
  .el-dropdown{
    margin-right: 20px;
  }
  .el-tooltip{
    margin-right: 10px;
  }
  span+.xiala{
    margin-left: 0;
    font-size: 12px;
  }

  .pifu{
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .pifu .icon{
    font-size: 18px;
    margin: 0;
  }
  .super-navs {
    padding-left: 80px;
    display: flex;
    cursor: pointer;
  }

  .super-nav:hover {
    background: #299cfe;
    color: #ffffff;
  }

  .super-nav.is-active {
    background: #008aff;
    color: #ffffff;
  }

  .super-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #008aff;
    padding: 7px 15px;
  }

  .super-nav p {
    font-size: 12px;
    line-height: 12px;
    margin-top: 8px;
  }

  .super-nav .icon {
    margin: 0;
  }

  .top {
    padding-left: 0;
  }
  .top-item{
    margin-right: 20px;
  }
  .icon {
    font-size: 30px;
    margin-top: 2px;
    margin-right: 6px;
  }

  .quanping .icon {
    font-size: 18px !important;
  }

  .setting {
    margin-left: 10px;
  }

  .setting .icon {
    font-size: 20px;
    margin-right: 0;
  }

  .user-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 2px;
    margin-right: 6px
  }

  .index .top {
    /*border-bottom: 1px solid #98C8FC;*/
  }

  .tags-breadcrumb {
    padding-left: 0;
  }
  .message{
    padding: 15px 10px 18px;
    width: 30px;
    position: relative;
    margin-right: 20px;
  }
  .message:hover{
      background: #eee;
    box-shadow:0px -2px 8px #ccc;
    .message-title{
    right: 0px;
		transition: .2s linear;
  }
  }
  .message:hover .pifu{
    background: #3f85ff;
    border: 1px solid #3f85ff;
  }
  .message:hover .icon{
    color: #fff;
  }
  .message .message-title{
    width: 310px;
    // padding-left: 12px;
    height:80vh;
    background: #eee;
    position: fixed;
    top: 64px;
    right:0;
		right: -310px;
    z-index: 99;
    box-shadow: -3px 0 8px #ccc;
  }
  .message .message-title .msC1{
    width: 134px;
    float: left;
    margin-top: 24px;
    color: #999;
    font-size: 16px;
  }
  .message .message-title .msC2{
    width: 102px;
    float: left;
    color: #3f85ff;
    font-size: 12px;
    border: 1px solid #3f85ff;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
  }
  .msg-list{
    display: block;
    height:calc(100% - 40px) ;
    overflow-y: auto;
    border-radius: 8px;
    padding:0 9px;
		background: #f3f3f3;
		h2{
			font-size:16px;
			font-weight:600;
			color:rgba(51,51,51,1);
      line-height:22px;
      padding:5px 0;
		}
    li{
      padding: 20px 14px;
      display: block;
      border-bottom: 1px solid #eee;
      .t1{
        color: #8cb2f4;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 8px;
        display: inline-block;
      }
      .t2{
        color: #aaa;
        font-size: 10px;
      }
      .msg-content{
        width: 213px;
        color: #000;
        font-size: 12px;
        white-space: pre-wrap;
        line-height: 18px;
        position: relative;
        i{
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #000;
          top: 5px;
          left: -8px;
        }
      }
    }
  }
	.msg-top{
		display: flex;
		height: 33px;
		padding-left: 7px;
		padding-right: 14px;
		justify-content: space-between;
		align-items: center;
		background: #fff;
		}
		.msg-top p{
			font-size:13px;
			font-weight:400;
			color:rgba(51,51,51,1);
			line-height:18px;
		}
		.msg-top  .el-link{
			font-size:10px;
			font-weight:400;
			color:rgba(51,133,255,1);
			line-height:14px;
		}
</style>

