/**
 * 平台菜单
 */

export default [

  {
    label: '受托业务',
    icon: 'icon-baojiajieshou',
    id:'3',
    children: [
      {
        label: '受托批次管理',
        icon: 'icon-fafangpici',
        path: '/consignee/batchIndex.action',
        permission: 'pub_1050',
        id:'3-1',
      },
      {
        label: '业务反馈',
        icon: 'icon-fankui',
        path: '/consignee/feedbackIndex.action',
        permission: 'pub_1060',
        id:'3-1',
      },
      {
        label: '受托历史记录查询',
        icon: 'icon-fafangpici',
        path: '/consignee/batchAction_historyBizTypeIndex.action?operateFlag=MTAwMQ--',
        permission: 'pub_1115',
        id:'3-1',
      },
      {
        label: '退票查询',
        icon: 'icon-tuipiao',
        path: '/consignor/payoutBills_stPackedRTicket.action',
        permission: 'pub_1135',
        id:'3-1',
      },
      {
        label: '汇款差额查询',
        icon: 'icon-zhuankuan',
        path: '/consignee/differenceAction_differenceIndex.action',
        permission: 'pub_1166',
        id:'3-1',
      },
      {
        label: '支付确认',
        icon: 'icon-fafangpici',
        path: '/consignee/payment_confirm',
        permission: 'pub_5017',
        id:'3-1',
      },
    ],
  },

];

