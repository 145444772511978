import request from '@/router/axios';

/**
 * 分页查询用户下的站内消息
 * @param query
 */
export function queryUserListPage(obj) {
  return request({
    url: '/message/message-log/page/my',
    method: 'get',
    params: obj
  });
}

/**
 * 根据类型分页查询用户下的站内消息
 * @param query
 */
export function queryListPageByType(obj) {
  return request({
    url: '/message/message-log/page/type',
    method: 'post',
    data: obj
  });
}

/**
 * 根据id批量修改站内消息状态
 * @param query
 */
export function updateBatchMsgStatus(obj) {
  return request({
    url: '/message/message-log/update/batch',
    method: 'put',
    data: obj
  });
}

/**
 * 根据id 批量删除站内消息
 * @param query
 */
export function deletBatchMsg(obj) {
  return request({
    url: '/message/message-log/delete/batch',
    method: 'delete',
    data: obj
  });
}
