<template>
  <div class="pull-height animated" :class="{'zoomOutUp': isLock}">
    <div class="index">
      <sidebar class="left" v-if="isRouterAlive"></sidebar>
      <div style="min-width:calc(100vw - 200px);flex: 1;max-width: calc(100vw - 64px);">
        <div class="nav" v-if="isUrl">
          <top class="top"  v-if="isRouterAlive"></top>
        </div>
        <div>
          <div class="right">
            <!--<div class="nav">-->
            <!--<top class="top"></top>-->
            <!--</div>-->
            <div class="main" ref="ciicMainBox">
              <div class="router" id="main" ref="ciicMain">
                <keep-alive>
                  <router-view @toTop="scrolltop" v-if="$route.meta.keepAlive && isRouterAlive"></router-view>
                </keep-alive>
                <router-view @toTop="scrolltop" v-if="!$route.meta.keepAlive && isRouterAlive"></router-view>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import top from "./top/";
  import sidebar from "./sidebar/";

  export default {
    components: {
      top,
      sidebar
    },
    name: "index",
    inject: ['reload'],
    data() {
      return {
        isRouterAlive: true,
        dicts: {},
        isUrl:true,
      };
    },
    created() {
      let url=this.$route.path;
      if(url.indexOf('printPaymentSlip') ===-1 &&
        url.indexOf('printRefundNotice') ===-1 &&
        url.indexOf('printEmployeeDetail') ===-1 &&
        url.indexOf('printPublicPayment') ===-1 &&
        url.indexOf('printPublicPaymentDetail') ===-1){
        this.isUrl=true
      }else{
        this.isUrl=false
      }
      document.documentElement.style.fontSize = '14px';
      this.getDicts().then((res) => {
        this.dicts = res.data.data;
        this.$store.commit("SET_DICTS", this.dicts);
      });
    },
    mounted() {
      // 主题 light-theme/白  dark-theme/黑
      if (typeof(localStorage.Theme) == "undefined") {
        localStorage.setItem("Theme", "light-theme");
        localStorage.setItem("Color", "#000000");
      }
      document.getElementsByTagName("body")[0].className = localStorage.getItem("Theme");
      this.$store.commit("SET_WORKSPACE_RECT", this.$refs.ciicMain);

    },
    computed: mapGetters(["isLock", "menu"]),
    props: [],
    provide() {
      return {
        reload: this.reload
      };
    },
    methods: {
      reload() {
        this.isRouterAlive = false;
        this.$nextTick(function() {
          this.isRouterAlive = true;
        });
      },
      // 回到顶部
      scrolltop() {
        this.$refs.ciicMainBox.scrollTop = 0;
      },
      reloadSider(){
        debugger;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .index {
    display: flex;
    position: relative;
    background: #fff;
    overflow: hidden;
    height: 100%;
    .left {
      width: 200px;
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;
      background: #393F6C;
    }
    .left:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 2px;
      height: 100%;
      z-index: 10;
    }
    .left.is-active:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0px;
      height: 100%;
      background-color: #7fc4ff;
    }
    .right {
      /*padding-top: 60px;*/
      position: relative;
      height: 100%;
      box-sizing: border-box;
      overflow: hidden;
      flex: 1;
    }
    .main {
      height: calc(100vh - 62px);
      background: #F5F5F5;
      box-sizing: border-box;
      overflow-y: scroll;
      .router {
        padding: 15px;
        background: #fff;
        min-height: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 5px;
        box-sizing: border-box;
      }
    }
    .nav {
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
    }
    .top {
      /*margin-bottom: 2px;*/
      height: 60px;
    }
  }

</style>

